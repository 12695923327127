<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="time"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="time"
        :append-icon="appendIcon"
        :append-outer-icon="appendOuterIcon"
        :background-color="backgroundColor"
        :class="customClass"
        :clearable="clearable"
        :dense="dense"
        :disabled="disabled"
        :label="label"
        :hide-details="hideDetails"
        :outlined="outlined"
        :placeholder="placeholder"
        :prepend-icon="prependIcon"
        :prepend-inner-icon="prependInnerIcon"
        :solo="solo"
        :style="customStyle"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>

    <v-time-picker
      class="tp-time-picker"
      v-if="menu"
      v-model="time"
      format="24hr"
      color="primary"
      scrollable
      no-title
      full-width
      @click:minute="$refs.menu.save(time)"
    ></v-time-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    appendIcon: {
      type: String
    },
    appendOuterIcon: {
      type: String
    },
    backgroundColor: {
      type: String
    },
    clearable: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: [String, Object]
    },
    customStyle: {
      type: [String, Object]
    },
    dense: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    filled: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: [Boolean, String]
    },
    label: {
      type: String
    },
    outlined: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String
    },
    prependIcon: {
      type: String
    },
    prependInnerIcon: {
      type: String
    },
    solo: {
      type: Boolean,
      default: false
    },
    value: {
      type: String
    }
  },
  data() {
    return {
      time: null,
      menu: false
    };
  },
  watch: {
    time(val) {
      this.$emit("change", val);
      this.$emit("input", val);
    },
    value() {
      this.time = this.value;
    }
  },
  created() {
    if (this.value) {
      this.time = this.value;
    }
  }
};
</script>

<style lang="scss" scoped>
.tp-time-picker {
  ::v-deep .v-time-picker-clock {
    background-color: #f7f7f7;
  }
}
</style>
